<template>
  <div class="geral" v-if="pedido != null">

    <div id="dadosEmpresa">
      <div class="titulo-geral">
        <strong>PEDIDO #{{ pedido.numero }}</strong><br>
        <small><strong>{{ pedido.data + " " + pedido.hora }}</strong></small><br>
        <small v-if="pedido.tipoEntrega.nome"><strong>Entrega: {{ pedido.tipoEntrega.nome }}</strong></small>
      </div>
      <div id="nomeEmpresa">{{ pedido.loja.nome }}</div>
      <div>{{ pedido.loja.fone }}</div>
    </div>

    <div id="cabecalho">

      <div>
        <span style="">Cliente:</span>
        {{ pedido.usuarios.nome }}
      </div>

      <div v-if="pedido.usuarios.fone">
        <span style="">Telefone:</span>
        {{ pedido.usuarios.fone }}
      </div>

      <div v-if="pedido.usuarios.lougradouro">
        <span style="">Endereço:</span> {{ pedido.usuarios.lougradouro }}
        <span v-if="pedido.usuarios.numeroCasa">, {{ pedido.usuarios.numeroCasa }}</span>
      </div>

      <div v-if="pedido.usuarios.complemento"><span style="">Complem.:</span> {{ pedido.usuarios.complemento }}</div>
      <div v-if="pedido.usuarios.bairro"><span style="">Bairro:</span> {{ pedido.usuarios.bairro }}</div>
      <div v-if="pedido.usuarios.cidade"><span style="">Cidade:</span> {{ pedido.usuarios.cidade }} <span v-if="pedido.usuarios.uf">- {{ pedido.usuarios.uf }}</span></div>
      <div v-if="pedido.usuarios.cep"><span style="">CEP:</span> {{ pedido.usuarios.cep }}</div>

    </div>

    <div class="produtos" v-for="produto of pedido.carrinho" :key="produto.id">

      <div class="produto">

        <div class="qnt">
          <strong>{{ produto.qnt }}x</strong>
        </div>

        <div class="nome">
          <strong>{{ produto.nome }}</strong>
        </div>

        <div class="valor">
          {{ $utils.formatCurrency(produto.valor * produto.qnt) }}
        </div>

      </div>

      <div class="produto" v-if="produto.observacao">
        <div class="qnt">&nbsp;</div>
        <div><strong>{{ "\nObs: " + produto.observacao }}</strong></div>
      </div>

      <div v-if="produto.personalizacoes">
        <div v-for="personalizacao of produto.personalizacoes" :key="personalizacao.id">
          <div class="produto" v-for="item of personalizacao.itens" :key="item.id">
            <div class="qnt">&nbsp;</div>
            <div class="nome">
              {{ item.qntSelecionado }} {{ item.nome }}
            </div>
            <div class="valor">
              {{ $utils.formatCurrency(item.valor * item.qntSelecionado) }}
            </div>
          </div>
        </div>
      </div>

    </div>

    <div id="totalizador">

      <div>
        Qnt Ítens:
        <div class="right">{{ pedido.qnt }}</div>
      </div>

      <div>
        <strong>Total Geral:</strong>
        <div class="right">
          <strong>{{ $utils.formatCurrency(pedido.valorTotal) }}</strong>
        </div>
      </div>

      <div>
        Total do Pedido:
        <div class="right">{{ $utils.formatCurrency(pedido.valorVenda) }}</div>
      </div>

      <div v-if="pedido.valorDesconto">
        Desconto:
        <div class="right">{{ $utils.formatCurrency(pedido.valorDesconto) }}</div>
      </div>

      <div v-if="pedido.valorEntrega">
        Entrega:
        <div class="right">{{ $utils.formatCurrency(pedido.valorEntrega) }}</div>
      </div>

      <!-- <div>
        Valor Recebido:
        <div class="right">{{ $utils.formatCurrency(pedido.valorPago) }}</div>
      </div> -->

      <div v-if="pedido.tipoPagamento">
        Forma pgto:
        <div class="right">{{ pedido.tipoPagamento + ' (' + pedido.pagamentoWeb + ') [' + (pedido.pagamentoConfirmado?"Recebido":"Pendente") + ']' }}</div>
      </div>

    </div>

  </div>
</template>
  
<script>

import firebase from 'firebase/app';
import 'firebase/database';

export default {

  data() {
    return {

      loja: null,
      pedido: null,
      carrinho: []

    };
  },

  async mounted() {

  },

  watch: {

    '$root.user': {
      handler: function (val) {
        this.loja = this.$utils.getStdObject(val);
      },
      deep: true,
      immediate: true
    },

    loja(loja) {
      if(loja != null) {
        this.carregarPedido();
      }
    }

  },

  methods: {

    async carregarPedido() {

      const snapshot = await firebase.database().ref("VipDelivery").child('Pedidos/Lojas')
      .child(this.loja.id).child(this.$route.params.id).once("value");

      if(snapshot.exists() == false) {
        alert('Pedido não encontrado!');
      } else {

        let pedido = snapshot.val();

        const carrinho = [];

        if(typeof pedido.listaProdutos == "object" && pedido.listaProdutos != null) {
          for(const p of Object.values(pedido.listaProdutos)) {

            if(p.qnt <= 0)
              continue;

            let produto = this.$utils.getStdObject(p);
            produto.personalizacoes = [];

            if(typeof p.personalizacoes == "object" && p.personalizacoes != null) {
              
              for(const pers of Object.values(p.personalizacoes)) {

                if(typeof pers.itens != "object" || pers.itens == null || Object.keys(pers.itens).length == 0)
                  continue;

                let personalizacao = this.$utils.getStdObject(pers);

                personalizacao.itens = [];

                for(const item of Object.values(pers.itens)) {

                  if(item.qntSelecionado > 0) {

                    personalizacao.itens.push(item);

                  }

                }

                if(personalizacao.itens.length > 0) {
                  produto.personalizacoes.push(personalizacao);
                }

              }

            }

            carrinho.push(produto);

          }

        }

        pedido.carrinho = carrinho;

        this.pedido = pedido;
        
        setTimeout(function () {
          window.print();
          window.close();
        }, 1500);

      }

    }

  }

};
</script>
  
<style scoped>
.geral {
  font-family: monospace;
  margin: 1em auto;
}

#dadosEmpresa {
  margin-bottom: 1.5em;
  text-align: center;
}

#nomeEmpresa {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.3em;
}

.right {
  float: right;
}

.produtos {
  border-top: 1px solid #000000;
  margin-top: 3px;
  padding-top: 3px;
}

.produto {
  display: flex;
}

.qnt {
  min-width: 1.9em;
  margin-right: 0.4em;
}

.nome {
  width: 100%;
}

.valor {
  min-width: 5.45em;
  margin-left: 0.4em;
  text-align: right;
}

.titulo-geral {
  display: block;
  font-size: 1.3em;
  margin-bottom: 1em;
  margin-top: 0.5em;
}

#totalizador {
  border-top: 1px solid #000000;
  margin-top: 3px;
  padding-top: 3px;
  padding-bottom: 20px;
}

</style>